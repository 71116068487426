import { h } from "preact"
import MinimizeControl from "../components/minimize-control"
import ArtistCollection from '../components/artist-collection'
import Footer from '../components/footer'
import { Artist, Rule, ValidationError } from '../domain'
import ArtistSearch from '../components/artist-search'
import ConsentInformation from '../components/consent-information'
import Blacklisted from '../components/blacklisted'
import ResendPermissionLink from '../components/resend-permission-link'
import Title from '../components/title'
import Description from '../components/description'
import { loadSubscribedArtists, compareArtists } from '../functions/artists'
import { validateConsentCheck } from '../functions/validation'
import { EviAPI } from '../evi-api'
import {Dispatch, StateUpdater, useEffect, useState} from 'preact/hooks'
import {
    ticketAlarmAdditionalClasses,
    ticketAlarmOnAnimationEnd,
    ticketAlarmOnSearchFocus,
    ticketAlarmOnSelectedAutocomplete
} from '../functions/ticketalarm'
import { hasAtLeastOneDoubleOptInAction, isActionDoubleOptIn } from '../functions/actions'
import { useAddArtist } from '../hooks/useAddArtist'

interface TicketAlarmCockpitProps {
    emitter: EventTarget
    rule: Rule
    agent: string
    email: string
    consentCheckEnabled: boolean
    consentChecked: boolean,
    onConsentCheckChange: (checked: boolean) => void,
    minimized: boolean
    onToggleMinimize: () => void
    eviAPI: EviAPI
}

export default function TicketAlarmCockpit (props: TicketAlarmCockpitProps) {
    const [artists, setArtists]: [Artist[], Dispatch<StateUpdater<Artist[]>>] = useState([])
    const [artistsPreview, setArtistsPreview]: [Artist[], Dispatch<StateUpdater<Artist[]>>]  = useState([])
    const [animateId, setAnimateId]: [string, Dispatch<StateUpdater<string | null>>] = useState(null)
    const [lastRemoved, setLastRemoved]: [boolean, Dispatch<StateUpdater<boolean | null>>] = useState(null)
    const [searchFocus, setSearchFocus]: [boolean, Dispatch<StateUpdater<boolean>>] = useState(false)
    const [blacklisted, setBlacklisted]: [boolean, Dispatch<StateUpdater<boolean | null>>] = useState(false)
    const [validationErrors, setValidationErrors]: [ValidationError[], Dispatch<StateUpdater<ValidationError[]>>] = useState([])

    useEffect(() => {
        loadSubscribedArtists(props.eviAPI, props.rule).then(artists => setArtists(artists))
    }, [props.eviAPI, props.rule])

    useAddArtist(props.emitter, artists, props.rule.doubleOptInType, props.rule.actions[0], setAnimateId, setArtists, setLastRemoved)

    const onRemoveArtistFromPreview = (artistToRemove) => {
        const newArtists = artistsPreview.filter(artist => artist.id !== artistToRemove.id)
        setArtistsPreview(newArtists)
        setLastRemoved(!newArtists.length)
    }

    const validate = () => {
        const validationErrors = validateConsentCheck(props.consentCheckEnabled, props.consentChecked)
        setValidationErrors(validationErrors)
        return !validationErrors.length
    }

    const onSubmit = async (e) => {
        e.preventDefault()
        if (!validate()) {
            return
        }
        const uniqueArtists = [...artists]
        artistsPreview.forEach(newArtist => {
            if (!artists.map(artist => artist.id).includes(newArtist.id)) {
                uniqueArtists.push(newArtist)
            }
        })
        uniqueArtists.sort(compareArtists)
        const artistIds = uniqueArtists.map(artist => artist.id)
        if (!artistIds.length) {
            return
        }
        const response = await props.eviAPI.postPermissionLink({
            ruleIds: [props.rule.id],
            context: {artistID: artistIds.join(',')}
        })
        const blacklisted = response.features.EMAIL === 'BLACKLISTED'
        if (!blacklisted) {
            props.emitter.dispatchEvent(new CustomEvent('widget-subscribe-success', { detail: { rules: [props.rule] }}))
        }
        setArtistsPreview([])
        setArtists(blacklisted ? [] : uniqueArtists)
        setBlacklisted(blacklisted)
    }

    const onRemoveArtist = async (artistToRemove) => {
        const newArtists = artists.filter(artist => artist.id !== artistToRemove.id)
        await props.eviAPI.deletePermissionLink({ruleIds: [props.rule.id], context: {artistID: artistToRemove.id}})
        props.emitter.dispatchEvent(new CustomEvent('widget-revoke-success', { detail: { rules: [props.rule] }}))
        setArtistsPreview([])
        setArtists(newArtists)
    }

    const hasAtLeastOnePendingPermissionLink = () => {
        return !!artists.find(artist => artist.pending)
    }

    return <div id={`evi-widget-rule-${props.rule.id}`}
                class={`evi-widget evi-widget-type-ticket-alarm-cockpit ${ticketAlarmAdditionalClasses(props.rule, lastRemoved, artists).join(' ')}`}>
        <MinimizeControl text={props.rule.data}
                         minimized={props.minimized}
                         onToggleMinimize={props.onToggleMinimize}/>
        <Title title={props.rule.data.title}/>
        {!props.minimized && <Description description={props.rule.data.text_above}/>}
        {!props.minimized &&
        <div class="evi-widget-artist-search-container">
            <label dangerouslySetInnerHTML={{__html: props.rule.data.title_inputSearch}}/>
            <div class="evi-widget-artists">
                <div class={`evi-widget-artists-search ${searchFocus ? 'evi-widget-artists-search-focus' : ''}`}>
                    <ArtistCollection artists={artistsPreview}
                                      onRemoveArtist={onRemoveArtistFromPreview}
                                      animateId={animateId}
                                      onAnimationEnd={() => ticketAlarmOnAnimationEnd(animateId)}/>
                    <ArtistSearch text={props.rule.data}
                                  onSearchFocus={(isFocus) => ticketAlarmOnSearchFocus(isFocus, setSearchFocus)}
                                  onSelectedAutocomplete={(artist) => ticketAlarmOnSelectedAutocomplete(artistsPreview, artist, isActionDoubleOptIn(props.rule.doubleOptInType, props.rule.actions[0]), setAnimateId, setArtistsPreview, setLastRemoved)}
                                  eviAPI={props.eviAPI}/>
                </div>
            </div>
            <form>
                <button class="evi-widget-submit" type="submit" onClick={onSubmit}
                        title={props.rule.data.label_button}
                        dangerouslySetInnerHTML={{__html: props.rule.data.label_button}}/>
            </form>
            <ConsentInformation text={props.rule.data}
                                ruleId={props.rule.id}
                                consentCheckEnabled={props.consentCheckEnabled}
                                onConsentCheckChange={props.onConsentCheckChange}
                                validationErrors={validationErrors}/>
        </div>}
        {!props.minimized && artists.length > 0 &&
        <div class="evi-widget-artist-collection">
            <div class="evi-widget-artist-collection-title" dangerouslySetInnerHTML={{__html: props.rule.data.text_below}}/>
            <ArtistCollection artists={artists}
                              agent={props.agent}
                              onRemoveArtist={onRemoveArtist}
                              animateId={null}
                              onAnimationEnd={() => ticketAlarmOnAnimationEnd(animateId)}/>
        </div>}
        {!props.minimized &&
        <ResendPermissionLink text={props.rule.data}
                              hasIdentity={true}
                              ruleId={props.rule.id}
                              hasDoubleOptInContent={hasAtLeastOneDoubleOptInAction(props.rule.doubleOptInType, props.rule.actions) && hasAtLeastOnePendingPermissionLink()}
                              eviAPI={props.eviAPI}/>}
        {!props.minimized &&
        <Blacklisted text={props.rule.data}
                     blacklisted={blacklisted}/>}
        {!props.minimized && <Footer text={props.rule.data}/>}
    </div>
}
