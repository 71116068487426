import { Component, h } from "preact"
import MinimizeControl from "../components/minimize-control"
import Footer from '../components/footer'
import { Action, ActionPermissionType, ActionType, PermissionLinkStatus, Rule, Template } from '../domain'
import ConsentInformation from '../components/consent-information'
import Blacklisted from '../components/blacklisted'
import ResendPermissionLink from '../components/resend-permission-link'
import { validateConsentCheck } from '../functions/validation'
import { EviAPI } from '../evi-api'
import ActionList from '../components/action-list'
import { isActionDoubleOptIn } from '../functions/actions'
import { flatMap } from '../functions/util'

interface GeneralNewsletterCockpitMultiProps {
    emitter: EventTarget
    rule: Rule
    agent: string
    email: string
    categories?: { id: string, name: string }[]
    consentCheckEnabled: boolean
    consentChecked: boolean,
    onConsentCheckChange: (checked: boolean) => void,
    minimized: boolean
    onToggleMinimize: () => void
    eviAPI: EviAPI
}

interface GeneralNewsletterCockpitMultiState {
    actions: Action[]
    validationErrors: string[]
    blacklisted: boolean
}

export default class GeneralNewsletterCockpit extends Component<GeneralNewsletterCockpitMultiProps, GeneralNewsletterCockpitMultiState> {
    constructor (props) {
        super(props)
        const actions = this.props.rule.actions
            .filter(action => action.type === ActionType.GENERAL)
            .map(action => {
                const actionName = this.props.rule.template === Template.GENERAL_NEWSLETTER_COCKPIT ? this.props.rule.data.title : (action.data ? action.data.title : '')
                return {...action, name: actionName}
            })
            .sort(this.sortActions)
        this.setState({
            blacklisted: false,
            validationErrors: [],
            actions
        })
    }

    sortActions = (a: Action, b: Action) => {
        const nameA = a.name ? a.name.toUpperCase() : ''
        const nameB = b.name ? b.name.toUpperCase() : ''
        return nameA.localeCompare(nameB)
    }

    onSubmit = async (e, actionId) => {
        const itemToUpdate = this.state.actions.find(action => action.id === actionId)
        if (e.target.checked) {
            if (!this.validate()) {
                return
            }

            const subscribe = (async () => {
                itemToUpdate.status = ActionPermissionType.HAVE_PERMISSION
                itemToUpdate.permissionLinkStatus = isActionDoubleOptIn(this.props.rule.doubleOptInType, itemToUpdate) ? PermissionLinkStatus.PENDING : PermissionLinkStatus.GRANTED
                this.setState({actions: [...this.state.actions]})
                const response = await this.props.eviAPI.postPermissionLink({ruleIds: [this.props.rule.id], actionId: itemToUpdate.id})
                const blacklisted = response.features.EMAIL === 'BLACKLISTED'
                if (blacklisted) {
                    itemToUpdate.status = ActionPermissionType.WANT_PERMISSION
                    this.setState({
                        actions: [...this.state.actions],
                        blacklisted
                    })
                } else {
                    this.props.emitter.dispatchEvent(new CustomEvent('widget-subscribe-success', { detail: { rules: [this.props.rule] }}))
                }
            }).bind(this)

            const event = new CustomEvent('evi-widget:subscribe', {cancelable: true, bubbles: true, detail: {rule: this.props.rule, subscribe}})
            e.currentTarget.dispatchEvent(event)
            if (!event.defaultPrevented) {
                subscribe()
            }
        } else {
            const revoke = (async () => {
                itemToUpdate.status = ActionPermissionType.WANT_PERMISSION
                itemToUpdate.permissionLinkStatus = PermissionLinkStatus.REVOKED
                this.setState({actions: [...this.state.actions]})
                await this.props.eviAPI.deletePermissionLink({ruleIds: [this.props.rule.id], actionId: itemToUpdate.id})
                this.props.emitter.dispatchEvent(new CustomEvent('widget-revoke-success', { detail: { rules: [this.props.rule] }}))
            }).bind(this)

            const event = new CustomEvent('evi-widget:revoke', {cancelable: true, bubbles: true, detail: {rule: this.props.rule, revoke}})
            e.currentTarget.dispatchEvent(event)
            if (!event.defaultPrevented) {
                revoke()
            }
        }
    }

    validate = () => {
        const validationErrors = validateConsentCheck(this.props.consentCheckEnabled, this.props.consentChecked)
        this.setState({validationErrors})
        return !validationErrors.length
    }

    hasAtLeastOnePendingPermissionLink = () => {
        return !!this.state.actions.find(action => action.permissionLinkStatus === PermissionLinkStatus.PENDING) ||
            !!flatMap(this.state.actions.map(action => action.permissionLinks)).find(permissionLink => permissionLink.status === PermissionLinkStatus.PENDING)
    }

    render () {
        const widgetClass = this.props.rule.template === Template.GENERAL_NEWSLETTER_COCKPIT ? 'evi-widget-type-general-newsletter-cockpit' : 'evi-widget-type-general-newsletter-cockpit-multi'
        return <div id={`evi-widget-rule-${this.props.rule.id}`} class={`evi-widget ${widgetClass} ${this.props.rule.cssClass || ''}`}>
            <MinimizeControl text={this.props.rule.data}
                             minimized={this.props.minimized}
                             onToggleMinimize={this.props.onToggleMinimize}/>
            <ActionList text={this.props.rule.data}
                        agent={this.props.agent}
                        doubleOptInType={this.props.rule.doubleOptInType}
                        actions={this.state.actions}
                        onSubmit={this.onSubmit}/>
            <Blacklisted text={this.props.rule.data} blacklisted={this.state.blacklisted}/>
            {!this.props.minimized && <ConsentInformation text={this.props.rule.data}
                                ruleId={this.props.rule.id}
                                consentCheckEnabled={this.props.consentCheckEnabled}
                                validationErrors={this.state.validationErrors}
                                onConsentCheckChange={this.props.onConsentCheckChange}/>}
            <ResendPermissionLink text={this.props.rule.data}
                                  hasIdentity={true}
                                  ruleId={this.props.rule.id}
                                  hasDoubleOptInContent={this.hasAtLeastOnePendingPermissionLink()}
                                  eviAPI={this.props.eviAPI}/>
            {!this.props.minimized && <Footer text={this.props.rule.data}/>}
        </div>
    }
}
