import {Component, h} from "preact"
import MinimizeControl from "../components/minimize-control"
import Footer from '../components/footer'
import {Category, PermissionLinkStatus, Rule} from '../domain'
import ConsentInformation from '../components/consent-information'
import Blacklisted from '../components/blacklisted'
import ResendPermissionLink from '../components/resend-permission-link'
import Title from '../components/title'
import Description from '../components/description'
import {validateConsentCheck} from '../functions/validation'
import {EviAPI} from '../evi-api'
import CategoryList from '../components/category-list'
import {constructCategories} from '../functions/categories'
import {isActionDoubleOptIn} from '../functions/actions'

interface CategoryNewsletterCockpitProps {
    emitter: EventTarget
    rule: Rule
    agent: string
    email: string
    categories?: { id: string, name: string }[]
    consentCheckEnabled: boolean
    consentChecked: boolean,
    onConsentCheckChange: (checked: boolean) => void,
    minimized: boolean
    onToggleMinimize: () => void
    eviAPI: EviAPI
}

interface CategoryNewsletterCockpitState {
    categories: Category[]
    validationErrors: string[]
    blacklisted: boolean
    revokeOnly: boolean
}

export default class CategoryNewsletterCockpit extends Component<CategoryNewsletterCockpitProps, CategoryNewsletterCockpitState> {
    constructor (props) {
        super(props)
        const revokeOnly = this.props.rule.cssClass && this.props.rule.cssClass.split(/\s+/).includes('evi-widget-revoke-only')
        const categories = constructCategories(this.props.rule.actions, this.props.categories, revokeOnly)
        this.setState({
            blacklisted: false,
            validationErrors: [],
            revokeOnly,
            categories
        })
    }

    onSubmit = async (e, categoryId) => {
        const itemToUpdate = this.state.categories.find(category => category.id === categoryId)
        if (e.target.checked) {
            if (this.state.revokeOnly || !this.validate()) {
                return
            }
            itemToUpdate.status = isActionDoubleOptIn(this.props.rule.doubleOptInType, this.props.rule.actions[0]) ? PermissionLinkStatus.PENDING : PermissionLinkStatus.GRANTED
            this.setState({categories: [...this.state.categories]})
            const response = await this.props.eviAPI.postPermissionLink({ruleIds: [this.props.rule.id], context: {categoryID: categoryId}})
            const blacklisted = response.features.EMAIL === 'BLACKLISTED'
            if (blacklisted) {
                itemToUpdate.status = PermissionLinkStatus.REVOKED
                this.setState({
                    categories: [...this.state.categories],
                    blacklisted
                })
            } else {
                this.props.emitter.dispatchEvent(new CustomEvent('widget-subscribe-success', { detail: { rules: [this.props.rule] }}))
            }
        } else {
            itemToUpdate.status = PermissionLinkStatus.REVOKED
            this.setState({categories: [...this.state.categories]})
            await this.props.eviAPI.deletePermissionLink({ruleIds: [this.props.rule.id], context: {categoryID: categoryId}})
            this.props.emitter.dispatchEvent(new CustomEvent('widget-revoke-success', { detail: { rules: [this.props.rule] }}))
        }
    }

    validate = () => {
        const validationErrors = validateConsentCheck(this.props.consentCheckEnabled, this.props.consentChecked)
        this.setState({validationErrors})
        return !validationErrors.length
    }

    hasAtLeastOnePendingPermissionLink = () => {
        return !!this.state.categories.find(category => category.status === PermissionLinkStatus.PENDING)
    }

    render () {
        const grantedPermissions = this.state.categories.filter(c => c.status === PermissionLinkStatus.GRANTED).length

        return <div id={`evi-widget-rule-${this.props.rule.id}`} class={`evi-widget evi-widget-type-category-newsletter-cockpit evi-widget-permissions-granted-count-${grantedPermissions} ${this.props.rule.cssClass || ''}`}>
            <MinimizeControl text={this.props.rule.data}
                             minimized={this.props.minimized}
                             onToggleMinimize={this.props.onToggleMinimize}/>
            <Title title={this.props.rule.data.title}/>
            {!this.props.minimized && <Description description={this.props.rule.data.text_above}/>}
            {!this.props.minimized &&
            <CategoryList text={this.props.rule.data}
                          agent={this.props.agent}
                          categories={this.state.categories}
                          onSubmit={this.onSubmit}/>}
            {!this.props.minimized &&
            <Blacklisted text={this.props.rule.data}
                         blacklisted={this.state.blacklisted}/>}
            {!this.props.minimized &&
            <ConsentInformation text={this.props.rule.data}
                                ruleId={this.props.rule.id}
                                consentCheckEnabled={this.props.consentCheckEnabled}
                                validationErrors={this.state.validationErrors}
                                onConsentCheckChange={this.props.onConsentCheckChange}/>}
            {!this.props.minimized &&
            <ResendPermissionLink text={this.props.rule.data}
                                  hasIdentity={true}
                                  ruleId={this.props.rule.id}
                                  hasDoubleOptInContent={this.hasAtLeastOnePendingPermissionLink()}
                                  eviAPI={this.props.eviAPI}/>}
            {!this.props.minimized && <Footer text={this.props.rule.data}/>}
        </div>
    }
}
