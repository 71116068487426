import { h } from "preact"
import { Artist, Rule, ValidationError } from '../domain'
import ArtistSearch from '../components/artist-search'
import ConsentInformation from '../components/consent-information'
import EmailSubmit from '../components/email-submit'
import GeneralError from '../components/general-error'
import ValidationErrorList from '../components/validation-error-list'
import ArtistCollection from '../components/artist-collection'
import Title from '../components/title'
import { EviAPI } from '../evi-api'
import GeneralListSuccess from '../components/general-list-success'
import {Dispatch, StateUpdater, useState} from 'preact/hooks'
import {
    ticketAlarmAdditionalClasses,
    TicketAlarmFlow,
    ticketAlarmOnAnimationEnd,
    ticketAlarmOnSearchFocus,
    ticketAlarmOnSelectedAutocomplete,
    ticketAlarmOnSubmit
} from '../functions/ticketalarm'
import { hasAtLeastOneDoubleOptInAction, isActionDoubleOptIn } from '../functions/actions'
import { useAddArtist } from '../hooks/useAddArtist'

interface TicketAlarmProps {
    emitter: EventTarget
    rule: Rule
    email: string
    hasIdentity: boolean
    consentCheckEnabled: boolean,
    consentChecked: boolean,
    onConsentCheckChange: (checked: boolean) => void,
    eviAPI: EviAPI
}

interface TicketAlarmValidationErrors {
    artistSearch: ValidationError[],
    email: ValidationError[],
    consent: ValidationError[]
}

export default function TicketAlarm (props: TicketAlarmProps) {
    const [artistsPreview, setArtistsPreview]: [Artist[], Dispatch<StateUpdater<Artist[]>>] = useState([])
    const [animateId, setAnimateId]: [string, Dispatch<StateUpdater<string | null>>] = useState(null)
    const [email, setEmail]: [string, Dispatch<StateUpdater<string>>] = useState(props.email)
    const [lastRemoved, setLastRemoved]: [boolean, Dispatch<StateUpdater<boolean | null>>] = useState(null)
    const [searchFocus, setSearchFocus]: [boolean, Dispatch<StateUpdater<boolean>>] = useState(false)
    const [flow, setFlow]: [TicketAlarmFlow, Dispatch<StateUpdater<TicketAlarmFlow>>] = useState(TicketAlarmFlow.FORM)
    const [validationErrors, setValidationErrors]: [TicketAlarmValidationErrors, Dispatch<StateUpdater<TicketAlarmValidationErrors>>] = useState({
        artistSearch: [],
        email: [],
        consent: []
    })

    useAddArtist(props.emitter, artistsPreview, props.rule.doubleOptInType, props.rule.actions[0], setAnimateId, setArtistsPreview, setLastRemoved)

    const onRemoveArtistFromPreview = (artistToRemove) => {
        const newArtists = artistsPreview.filter(artist => artist.id !== artistToRemove.id)
        setArtistsPreview(newArtists)
        setLastRemoved(!newArtists.length)
    }

    const onBackLink = () => {
        setArtistsPreview([])
        setFlow(TicketAlarmFlow.FORM)
    }

    const extendedWidget = () => {
        return !!artistsPreview.length
    }

    const renderForm = () => {
        return <div class="evi-widget-content">
            <div class="evi-widget-artist-search-container">
                <Title title={props.rule.data.title}/>
                <div class="evi-widget-artists">
                    <div class={`evi-widget-artists-search ${searchFocus ? 'evi-widget-artists-search-focus' : ''}`}>
                        <ArtistCollection artists={artistsPreview}
                                          onRemoveArtist={onRemoveArtistFromPreview}
                                          animateId={animateId}
                                          onAnimationEnd={() => ticketAlarmOnAnimationEnd(setAnimateId)}/>
                        <ArtistSearch text={props.rule.data}
                                      onSearchFocus={(isFocus) => ticketAlarmOnSearchFocus(isFocus, setSearchFocus)}
                                      onSelectedAutocomplete={(artist) => ticketAlarmOnSelectedAutocomplete(artistsPreview, artist, isActionDoubleOptIn(props.rule.doubleOptInType, props.rule.actions[0]), setAnimateId, setArtistsPreview, setLastRemoved)}
                                      validationErrors={validationErrors.artistSearch}
                                      eviAPI={props.eviAPI}/>
                    </div>
                    <ValidationErrorList text={props.rule.data}
                                         validationErrors={validationErrors.artistSearch}/>
                </div>
                {extendedWidget() && <EmailSubmit text={props.rule.data}
                                                  ruleId={props.rule.id}
                                                  email={email}
                                                  showLabel={true}
                                                  hasIdentity={props.hasIdentity}
                                                  setEmail={setEmail}
                                                  onSubmit={e => ticketAlarmOnSubmit(e, artistsPreview, email, props, setFlow, setValidationErrors)}
                                                  validationErrors={validationErrors.email}/>}
                {extendedWidget() && <ConsentInformation text={props.rule.data}
                                                         ruleId={props.rule.id}
                                                         onConsentCheckChange={props.onConsentCheckChange}
                                                         consentCheckEnabled={props.consentCheckEnabled}
                                                         validationErrors={validationErrors.consent}/>}
            </div>
        </div>
    }

    return <div id={`evi-widget-rule-${props.rule.id}`} class={`evi-widget evi-widget-type-ticket-alarm ${ticketAlarmAdditionalClasses(props.rule, lastRemoved, null, flow, isActionDoubleOptIn(props.rule.doubleOptInType, props.rule.actions[0])).join(' ')}`}>
        {{
            [TicketAlarmFlow.FORM]: renderForm(),
            [TicketAlarmFlow.SUCCESS]: <GeneralListSuccess text={props.rule.data}
                                                           subscriptions={artistsPreview.map(artistsPreview => artistsPreview.name)}
                                                           isDoubleOptInEnabled={hasAtLeastOneDoubleOptInAction(props.rule.doubleOptInType, props.rule.actions)}
                                                           onBackLink={onBackLink}/>,
            [TicketAlarmFlow.ERROR]: <GeneralError text={props.rule.data}
                                                   onBackLink={onBackLink}/>
        }[flow]}
    </div>
}
