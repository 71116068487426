import { Component, h } from 'preact'
import { IdentityFeatureType, RuleResponse, Template, Token, WidgetOptions } from './domain'
import { decodeUrlSafeBase64 } from './functions/decode'
import { api, EviAPI } from './evi-api'
import Widget from './widgets/Widget'
import FormWidget from './widgets/FormWidget'

interface WidgetsInitializerProps {
    options: WidgetOptions
    emitter: EventTarget
}

interface WidgetsInitializerState {
    ruleResponse: RuleResponse
}

export default class WidgetsInitializer extends Component<WidgetsInitializerProps, WidgetsInitializerState> {
    private token: Token
    private email: string
    private eviAPI: EviAPI

    async componentDidMount () {
        this.token = this.parseToken(this.props.options.token)
        this.email = this.emailFromToken(this.token)
        this.eviAPI = api(this.props.options, this.token, null, this.email)
        const responseJSON = await this.eviAPI.getRule(this.props.options.token)
        this.eviAPI.updateRevision(responseJSON.revision)
        // eslint-disable-next-line react/no-did-mount-set-state
        this.setState({ruleResponse: responseJSON})
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    componentDidUpdate (previousProps: Readonly<WidgetsInitializerProps>, previousState: Readonly<WidgetsInitializerState>, snapshot: any): void {
        this.props.emitter.dispatchEvent(new CustomEvent('widget-ready', {detail: {ruleCount: this.state.ruleResponse ? this.state.ruleResponse.rules.length : 0}}))
    }

    parseToken = (token): Token => {
        const segments = token.split('.')
        if (segments.length < 2) {
            throw Error(`Token: ${token} not a valid JWT token`)
        }
        return JSON.parse(decodeUrlSafeBase64(segments[1]))
    }

    emailFromToken = (token) => {
        let result = null
        if (token.identityFeatures) {
            token.identityFeatures.forEach((identityFeature) => {
                if (identityFeature.type === IdentityFeatureType.EMAIL) {
                    result = identityFeature.value
                }
            })
        }
        return result
    }

    render () {
        if (this.state.ruleResponse && this.state.ruleResponse.rules) {
            const isFormRule = (rule => (rule.template === Template.CHECKBOX || rule.template === Template.RADIO_BUTTON))
            const formRules = this.state.ruleResponse.rules.filter(rule => isFormRule(rule))
            const widgetRules = this.state.ruleResponse.rules.filter(rule => !isFormRule(rule))
            const widgets = widgetRules.map(rule => <Widget
                key={rule.id}
                emitter={this.props.emitter}
                token={this.token}
                rule={rule}
                email={this.email}
                categories={this.props.options.categories}
                hasIdentity={!!this.email}
                consentCheckEnabled={rule.cssClass && rule.cssClass.includes('evi-consent-check')}
                eviAPI={this.eviAPI}/>
            )
            // checkbox/radio widget is kind of different and is initialized with multiple rules
            widgets.push(<FormWidget rules={formRules}
                                     emitter={this.props.emitter}
                                     token={this.token}
                                     options={this.props.options}
                                     revision={this.state.ruleResponse.revision}
                                     eviAPI={this.eviAPI}/>)
            return widgets
        }
    }
}

