import { h } from "preact"
import Footer from '../components/footer'
import { Artist, Rule } from '../domain'
import ArtistSearch from '../components/artist-search'
import ConsentInformation from '../components/consent-information'
import EmailSubmit from '../components/email-submit'
import GeneralError from '../components/general-error'
import ValidationErrorList from '../components/validation-error-list'
import ResendPermissionLink from '../components/resend-permission-link'
import ArtistCollection from '../components/artist-collection'
import Title from '../components/title'
import Description from '../components/description'
import { loadSubscribedArtists } from '../functions/artists'
import { EviAPI } from '../evi-api'
import GeneralListSuccess from '../components/general-list-success'
import {Dispatch, StateUpdater, useEffect, useState} from 'preact/hooks'
import {
    ticketAlarmAdditionalClasses,
    TicketAlarmFlow,
    ticketAlarmOnAnimationEnd,
    ticketAlarmOnSearchFocus,
    ticketAlarmOnSelectedAutocomplete,
    ticketAlarmOnSubmit,
    TicketAlarmValidationErrors
} from '../functions/ticketalarm'
import { hasAtLeastOneDoubleOptInAction, isActionDoubleOptIn } from '../functions/actions'
import { useAddArtist } from '../hooks/useAddArtist'

interface TicketAlarm2Props {
    emitter: EventTarget
    rule: Rule
    email: string
    hasIdentity: boolean
    consentCheckEnabled: boolean,
    consentChecked: boolean,
    onConsentCheckChange: (checked: boolean) => void,
    eviAPI: EviAPI
}

export default function TicketAlarm2 (props: TicketAlarm2Props) {
    const [artists, setArtists]: [Artist[], Dispatch<StateUpdater<Artist[]>>] = useState([])
    const [initialArtists, setInitialArtists]: [Artist[], Dispatch<StateUpdater<Artist[]>>] = useState([])
    const [animateId, setAnimateId]: [string, Dispatch<StateUpdater<string | null>>] = useState(null)
    const [flow, setFlow]: [TicketAlarmFlow, Dispatch<StateUpdater<TicketAlarmFlow>>] = useState(TicketAlarmFlow.FORM)
    const [lastRemoved, setLastRemoved]: [boolean, Dispatch<StateUpdater<boolean | null>>] = useState(null)
    const [searchFocus, setSearchFocus]: [boolean, Dispatch<StateUpdater<boolean>>] = useState(false)
    const [email, setEmail]: [string, Dispatch<StateUpdater<string>>] = useState(props.email)
    const [validationErrors, setValidationErrors]: [TicketAlarmValidationErrors, Dispatch<StateUpdater<TicketAlarmValidationErrors>>] = useState({
        artistSearch: [],
        email: [],
        consent: []
    })

    useEffect(() => {
        loadSubscribedArtists(props.eviAPI, props.rule).then(artists => {setArtists(artists); setInitialArtists(artists)})
    }, [props.eviAPI, props.rule])

    useAddArtist(props.emitter, artists, props.rule.doubleOptInType, props.rule.actions[0], setAnimateId, setArtists, setLastRemoved)

    const onRemoveArtist = async (artistToRemove) => {
        const newArtists = artists.filter(artist => artist.id !== artistToRemove.id)
        await props.eviAPI.deletePermissionLink({ruleIds: [props.rule.id], context: {artistID: artistToRemove.id}})
        props.emitter.dispatchEvent(new CustomEvent('widget-revoke-success', { detail: { rules: [props.rule] }}))
        setArtists(newArtists)
        setLastRemoved(!newArtists.length)
    }

    const pendingArtists = (): string[] => artists.filter(artist => artist.pending).map(newArtists => newArtists.name)

    const hasAtLeastOnePendingPermissionLink = (): boolean =>  !!pendingArtists().length

    const hasAtLeastInitialOnePendingPermissionLink = (): boolean =>  !!initialArtists.find(artist => artist.pending)

    const renderForm = () => {
        return <div class="evi-widget-content">
            <div class="evi-widget-artist-search-container">
                <Title title={props.rule.data.title}/>
                <Description description={props.rule.data.text_above}/>
                <div class="evi-widget-artists">
                    <div class={`evi-widget-artists-search ${searchFocus ? 'evi-widget-artists-search-focus' : ''}`}>
                        <ArtistSearch text={props.rule.data}
                                      onSearchFocus={(isFocus) => ticketAlarmOnSearchFocus(isFocus, setSearchFocus)}
                                      onSelectedAutocomplete={artist => ticketAlarmOnSelectedAutocomplete(artists, artist, isActionDoubleOptIn(props.rule.doubleOptInType, props.rule.actions[0]), setAnimateId, setArtists, setLastRemoved)}
                                      validationErrors={validationErrors.artistSearch}
                                      eviAPI={props.eviAPI}/>
                    </div>
                    <ValidationErrorList text={props.rule.data}
                                         validationErrors={validationErrors.artistSearch}/>
                </div>
            </div>
            {artists.length > 0 &&
            <div class="evi-widget-artist-collection">
                <div class="evi-widget-artist-collection-title" dangerouslySetInnerHTML={{__html: props.rule.data.text_below}}/>
                <ArtistCollection artists={artists}
                                  onRemoveArtist={onRemoveArtist}
                                  animateId={animateId}
                                  onAnimationEnd={() => ticketAlarmOnAnimationEnd(setAnimateId)}/>
                <EmailSubmit text={props.rule.data}
                             ruleId={props.rule.id}
                             email={email}
                             hideEmail={!hasAtLeastOnePendingPermissionLink()}
                             hasIdentity={props.hasIdentity}
                             setEmail={setEmail}
                             onSubmit={e => ticketAlarmOnSubmit(e, artists, email, props, setFlow, setValidationErrors)}
                             validationErrors={validationErrors.email}/>
                {hasAtLeastOnePendingPermissionLink() && <ConsentInformation text={props.rule.data}
                                    ruleId={props.rule.id}
                                    onConsentCheckChange={props.onConsentCheckChange}
                                    consentCheckEnabled={props.consentCheckEnabled}
                                    validationErrors={validationErrors.consent}/>}
                {hasAtLeastOnePendingPermissionLink() &&  hasAtLeastInitialOnePendingPermissionLink() && <ResendPermissionLink text={props.rule.data}
                                      hasIdentity={props.hasIdentity}
                                      ruleId={props.rule.id}
                                      hasDoubleOptInContent={hasAtLeastOneDoubleOptInAction(props.rule.doubleOptInType, props.rule.actions)}
                                      eviAPI={props.eviAPI}/>}
            </div>
            }
        </div>
    }

    return <div id={`evi-widget-rule-${props.rule.id}`}
                class={`evi-widget evi-widget-type-ticket-alarm ${ticketAlarmAdditionalClasses(props.rule, lastRemoved, artists, flow, isActionDoubleOptIn(props.rule.doubleOptInType, props.rule.actions[0])).join(' ')}`}>
        {{
            [TicketAlarmFlow.FORM]: renderForm(),
            [TicketAlarmFlow.SUCCESS]: <GeneralListSuccess text={props.rule.data}
                                                           isDoubleOptInEnabled={hasAtLeastOneDoubleOptInAction(props.rule.doubleOptInType, props.rule.actions)}
                                                           subscriptions={pendingArtists()}/>,
            [TicketAlarmFlow.ERROR]: <GeneralError text={props.rule.data}/>
        }[flow]}
        <Footer text={props.rule.data}/>
    </div>
}
