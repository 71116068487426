import { Component, h } from "preact"
import { Capture, OptInType, PermissionLinkStatus, Rule, Template, Token, WidgetOptions } from '../domain'
import ConsentInformation from '../components/consent-information'
import { validateConsentCheck, validateEmail } from '../functions/validation'
import { EviAPI } from '../evi-api'
import { flatMap } from '../functions/util'
import { hasAllPermissionLinksGranted } from '../functions/actions'

enum ValidationError {
    MAIL_INPUT_SELECTOR_NOT_PROVIDED = 'MAIL_INPUT_SELECTOR_NOT_PROVIDED',
    EMAIL_INPUT_NOT_FOUND = 'EMAIL_INPUT_NOT_FOUND',
    EMAIL_EMPTY = 'EMAIL_EMPTY',
    EMAIL_INVALID = 'EMAIL_INVALID',
    CONSENT_CHECK_REQUIRED = 'CONSENT_CHECK_REQUIRED',
    NO_VALUE_SELECTED = 'NO_VALUE_SELECTED'
}

interface SaveParams {
    result: (result: {created: number, revoked: number}) => void
    mailInputSelector?: string
    error?: (msg?: string) => void
    success?: (response?: {}) => void
    timeout?: number
}

interface CaptureParams {
    result: (result: string) => void
    mailInputSelector?: string
    error: (msg?: string) => void
    success: (response?: {}) => void
    timeout?: number
}

interface ValidateParams {
    result: (result: ValidationError[]) => void
    mailInputSelector?: string
}

interface FormWidgetProps {
    token: Token
    revision: string
    options: WidgetOptions
    rules: Rule[]
    emitter: EventTarget
    eviAPI: EviAPI
}

interface FormWidgetState {
    value: { [key: string]: boolean }
    consentChecked: { [key: string]: boolean }
    validationErrors: { [key: string]: string[] }
}

export default class FormWidget extends Component<FormWidgetProps, FormWidgetState> {
    readonly initialValue
    readonly isRadio

    constructor (props) {
        super(props)
        this.props.emitter.addEventListener('save', this.onSave)
        this.props.emitter.addEventListener('capture', this.onCapture)
        this.props.emitter.addEventListener('validate', this.onValidate)
        this.isRadio = this.props.rules && this.props.rules.length && this.props.rules[0].template === Template.RADIO_BUTTON
        this.initialValue = this.isRadio ?
            this.props.rules.reduce((acc, rule) => ({...acc, [rule.id]: this.deriveRadioValue(rule)}), {}) :
            this.props.rules.reduce((acc, rule) => ({...acc, [rule.id]: this.deriveCheckboxValue(rule)}), {})
        this.setState({
            consentChecked: {},
            value: {...this.initialValue},
            validationErrors: {}
        })
    }

    onConsentCheckChange = (rule: Rule) => {
        return (checked) => this.setState({consentChecked: {...this.state.consentChecked, [rule.id]: checked}})
    }

    deriveRadioValue = (rule: Rule) => {
        const value = rule.actions.reduce((acc: boolean, action) => action.permissionLinks.reduce((acc: boolean, pm) => pm.status !== PermissionLinkStatus.REVOKED, undefined), undefined)
        // if no permission exists but identity exists, always show permission as denied.
        return value === undefined && this.props.token.identityFeatures && this.props.token.identityFeatures.length ? false : value
    }

    deriveCheckboxValue = (rule) => {
        switch (rule.optInType) {
            case OptInType.OPT_IN:
                return hasAllPermissionLinksGranted(rule.actions)
            case OptInType.OPT_OUT:
                return true
            case OptInType.REVERSE_OPT_IN:
                return !hasAllPermissionLinksGranted(rule.actions)
            case OptInType.REVERSE_OPT_OUT:
                return false
        }
    }

    onValidate = (event: Event) => {
        const params: ValidateParams = (event as CustomEvent).detail
        const validationErrors = []
        const emailEl: HTMLInputElement = document.querySelector(params.mailInputSelector)
        if (!params.mailInputSelector) {
            validationErrors.push(ValidationError.MAIL_INPUT_SELECTOR_NOT_PROVIDED)
        } else if (!emailEl) {
            validationErrors.push(ValidationError.EMAIL_INPUT_NOT_FOUND)
        } else if (!emailEl.value) {
            validationErrors.push(ValidationError.EMAIL_EMPTY)
        } else {
            const emailValue = emailEl.value.trim()
            if (validateEmail(emailValue, false).length) {
                validationErrors.push(ValidationError.EMAIL_INVALID)
            }
        }
        if (this.props.rules.some(rule => validateConsentCheck(this.consentCheckEnabled(rule), this.state.consentChecked[rule.id]).length)) {
            validationErrors.push(ValidationError.CONSENT_CHECK_REQUIRED)
        }
        if (Object.values(this.state.value).some(v => v === undefined)) {
            validationErrors.push(ValidationError.NO_VALUE_SELECTED)
        }
        if (validationErrors.length) {
            params.result(validationErrors)
        }
    }

    // this.onValidate tests the same. But we need this for backward compatibility.
    isValidEmailInput = (emailEl, mailInputSelector: string, errorFn?: (value) => void): boolean => {
        if (!mailInputSelector) {
            errorFn?.('No mailInputSelector defined in options')
            return false
        }
        if (!emailEl) {
            errorFn?.('email input not found')
            return false
        }
        if (!emailEl.value) {
            errorFn?.('email input is empty')
            return false
        }
        const emailValue = emailEl.value.trim()
        if (validateEmail(emailValue, false).length) {
            errorFn?.(`Invalid email: ${emailValue}`)
            return false
        }
        return this.validate()
    }

    onSave = (event: Event): { created: number, revoked: number } => {
        const params: SaveParams = (event as CustomEvent).detail
        const emailEl: HTMLInputElement = document.querySelector(params.mailInputSelector)
        if(!this.isValidEmailInput(emailEl, params.mailInputSelector, params.error)) {
            return
        }
        const ruleIds = this.assembleRules()
        FormWidget.doSave(this.props.eviAPI, ruleIds, emailEl.value, {
            ...params, 
            success: (response?: unknown): void => {
                // always intercept success case in order to trigger subscription events
                const createRules = this.props.rules.filter(rule => ruleIds.create.includes(rule.id))
                createRules.length && this.props.emitter.dispatchEvent(new CustomEvent('widget-subscribe-success', { detail: { rules: createRules}} ))
                const revokeRules = this.props.rules.filter(rule => ruleIds.revoke.includes(rule.id))
                revokeRules.length && this.props.emitter.dispatchEvent(new CustomEvent('widget-revoke-success', { detail: { rules: revokeRules}} ))
                // then passthrough to optional provided custom callback
                params.success?.(response)
            }
        })
        params.result({created: ruleIds.create.length, revoked: ruleIds.revoke.length})
    }

    onCapture = (event: Event): void => {
        const params: CaptureParams = (event as CustomEvent).detail
        const emailEl: HTMLInputElement = document.querySelector(params.mailInputSelector)
        if(!this.isValidEmailInput(emailEl, params.mailInputSelector, params.error)) {
            return
        }
        const result: Capture = {
            rules: this.assembleRules(),
            identityFeatureValue: emailEl.value,
            revision: this.props.revision,
            token: this.props.token,
            options: this.props.options
        }
        params.result(btoa(JSON.stringify(result)))
    }

    validate = () => {
        const allValidationErrors = this.props.rules.reduce((acc, rule) => ({...acc, [rule.id]: validateConsentCheck(this.consentCheckEnabled(rule), this.state.consentChecked[rule.id])}), {})
        this.setState({validationErrors: allValidationErrors})
        return !flatMap(Object.values(allValidationErrors)).length
    }

    consentCheckEnabled = (rule) => {
        return rule.cssClass && rule.cssClass.includes('evi-consent-check')
    }

    onChange = (rule, value) => {
        this.setState({value: {...this.state.value, [rule.id]: value}})
    }

    private assembleRules = (): {create: string[], revoke: string[]} => {
        const createRules = []
        const revokeRules = []
        this.props.rules.forEach(rule => {
            const selected = this.state.value[rule.id]
            if (this.isRadio) {
                if(selected !== undefined) {  // Three state logic for selected. Do nothing when selected === undefined
                    selected ? createRules.push(rule.id) : revokeRules.push(rule.id)
                }
            } else {
                const subscription = (selected && ((rule.optInType === OptInType.OPT_IN) || (rule.optInType === OptInType.OPT_OUT))) ||
                    (!selected && ((rule.optInType === OptInType.REVERSE_OPT_IN) || (rule.optInType === OptInType.REVERSE_OPT_OUT)))
                subscription ? createRules.push(rule.id) : revokeRules.push(rule.id)
            }
        })
        return {create: createRules, revoke: revokeRules}
    }

    static doSave(eviAPI: EviAPI, rules: {create: string[], revoke: string[]}, email: string,
                  params: {success?: (response?: {}) => void, timeout?: number}) {
        const promises = []
        if (rules.create.length > 0) {
            promises.push(eviAPI.postPermissionLink({ruleIds: rules.create, email}, params.timeout))
        }
        if (rules.revoke.length > 0) {
            promises.push(eviAPI.deletePermissionLink({ruleIds: rules.revoke, email}, params.timeout))
        }
        promises.length ? Promise.all(promises).then(responses => params.success?.(responses)) : params.success?.()
        return {created: rules.create.length, revoked: rules.revoke.length}
    }

    renderRadio () {
        return this.props.rules.map(rule =>
            <div key={rule.id} id={`evi-widget-rule-${rule.id}`} class={`evi-widget evi-widget-type-radio ${rule.cssClass || ''}`}>
                {!!rule.data.text_above && <div class="evi-widget-text-above" dangerouslySetInnerHTML={{__html: rule.data.text_above}}/>}
                <input id={`evi-widget-radio-${rule.id}-subscribe`}
                       name={`evi-widget-radio-${rule.id}`}
                       class="evi-widget-radio"
                       type="radio"
                       value="subscribe"
                       onChange={() => this.onChange(rule, true)} checked={this.state.value[rule.id]}/>
                <label for={`evi-widget-radio-${rule.id}-subscribe`} class="evi-widget-label" dangerouslySetInnerHTML={{__html: rule.data.text_radio_subscribe}}/>
                <input id={`evi-widget-radio-${rule.id}-unsubscribe`}
                       name={`evi-widget-radio-${rule.id}`}
                       class="evi-widget-radio"
                       type="radio"
                       value="unsubscribe"
                       onChange={() => this.onChange(rule, false)} checked={this.state.value[rule.id] === false}/>
                <label for={`evi-widget-radio-${rule.id}-unsubscribe`} class="evi-widget-label" dangerouslySetInnerHTML={{__html: rule.data.text_radio_unsubscribe}}/>
                <ConsentInformation text={rule.data}
                                    ruleId={rule.id}
                                    validationErrors={this.state.validationErrors[rule.id] || []}
                                    consentCheckEnabled={this.consentCheckEnabled(rule)}
                                    onConsentCheckChange={this.onConsentCheckChange(rule)}/>
                {!!rule.data.text_below && <div class="evi-widget-text-below" dangerouslySetInnerHTML={{__html: rule.data.text_below}}/>}
            </div>
        )
    }

    renderCheckbox () {
        return this.props.rules.filter(rule => !hasAllPermissionLinksGranted(rule.actions)).map(rule =>
            <div key={rule.id} id={`evi-widget-rule-${rule.id}`} class={`evi-widget evi-widget-type-checkbox ${rule.cssClass || ''}`}>
                {!!rule.data.text_above && <div class="evi-widget-text-above" dangerouslySetInnerHTML={{__html: rule.data.text_above}}/>}
                <input id={`evi-widget-checkbox-${rule.id}`}
                       class="evi-widget-checkbox"
                       type="checkbox"
                       onClick={(e: any) => this.onChange(rule, e.target.checked)} checked={!!this.state.value[rule.id]}/>
                <label for={`evi-widget-checkbox-${rule.id}`} class="evi-widget-label" dangerouslySetInnerHTML={{__html: rule.data.text_checkbox}}/>
                <ConsentInformation text={rule.data}
                                    ruleId={rule.id}
                                    validationErrors={this.state.validationErrors[rule.id] || []}
                                    consentCheckEnabled={this.consentCheckEnabled(rule)}
                                    onConsentCheckChange={this.onConsentCheckChange(rule)}/>
                {!!rule.data.text_below && <div class="evi-widget-text-below" dangerouslySetInnerHTML={{__html: rule.data.text_below}}/>}
            </div>
        )
    }

    render () {
        return this.isRadio ? this.renderRadio() : this.renderCheckbox()
    }
}
