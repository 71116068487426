import {Component, ComponentChild, h} from 'preact'
import { Artist, ArtistResponse } from '../domain'
import { EviAPI } from '../evi-api'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const dummyArtist = require('../../images/dummy-40px.jpg')

interface ArtistSearchProps {
    text: { [key: string]: string }
    onSearchFocus: (isFocus: boolean) => void
    onSelectedAutocomplete: (artist: Artist) => void
    validationErrors?: string[]
    eviAPI: EviAPI
}

interface ArtistSearchState {
    artistsAutocomplete: Artist[]
    selected: number
}

export default class ArtistSearch extends Component<ArtistSearchProps, ArtistSearchState> {
    private input: HTMLInputElement

    constructor (props) {
        super(props)
        this.setState({
            artistsAutocomplete: [],
            selected: 0,
        })
    }

    componentDidMount (): void {
        document.addEventListener('click', () => this.onReset())
        document.addEventListener('keydown', (e: KeyboardEvent) => e.key === 'Escape' ? this.onReset() : null)
    }

    onSearchInput = async (e: KeyboardEvent): Promise<void> => {
        if (e.key === 'ArrowUp') {
            this.setState({selected: Math.max(this.state.selected - 1, 0)})
        } else if (e.key === 'ArrowDown') {
            this.setState({selected: Math.min(this.state.selected + 1, this.state.artistsAutocomplete.length - 1)})
        } else if (e.key === 'Enter') {
            this.onSelectedAutocomplete(this.state.artistsAutocomplete[this.state.selected])
        } else {
            const artists: ArtistResponse[] = await this.props.eviAPI.getArtistSuggestions(this.input.value)
            this.setState({artistsAutocomplete: artists.map(artist => ({...artist, id: artist.artistId}))})
        }
    }

    onReset(): void {
        if (this.state.artistsAutocomplete.length) {
            this.input.value = ''
            this.setState({selected: 0, artistsAutocomplete: []})
        }
    }

    onSelectedAutocomplete (artist): void {
        this.props.onSelectedAutocomplete(artist)
        this.setState({selected: 0, artistsAutocomplete: []})
        this.input.value = ''
    }

    onMouseOver (artistId: string): void {
        this.setState({selected: this.state.artistsAutocomplete.findIndex(artist => artist.id === artistId)})
    }

    onMouseLeave (): void {
        this.setState({selected: -1})
    }

    formatArtistName (artistName): ComponentChild {
        const formattedArtist = artistName.replace(new RegExp(`(${this.input.value})`, 'gi'), '<strong>$1</strong>')
        return <a dangerouslySetInnerHTML={{__html: formattedArtist}}/>
    }

    createAutocomplete (): ComponentChild {
        const artistsAutocomplete = this.state.artistsAutocomplete.map((artist, i) =>
            <li class={i === this.state.selected ? 'evi-widget-artists-result-selection' : ''} key={artist.id}
                data-artist-id={artist.id}
                data-name={artist.name}
                onMouseOver={() => this.onMouseOver(artist.id)}
                onMouseLeave={() => this.onMouseLeave()}
                onClick={() => this.onSelectedAutocomplete(artist)}>
                <img src={artist.thumbnailUri || dummyArtist}/>{this.formatArtistName(artist.name)}
            </li>
        )
        return artistsAutocomplete.length ?
            <div class="evi-widget-artists-result">
                <ul>{artistsAutocomplete}</ul>
            </div> : null
    }

    render (): ComponentChild {
        return <div>
            <input onKeyUp={this.onSearchInput}
                   name="search"
                   aria-label="search" // currently for tests only, may be subject to change
                   ref={input => this.input = input}
                   placeholder={this.props.text.text_helpsearchfield}
                   autocomplete="off"
                   onFocus={() => this.props.onSearchFocus(true)}
                   onBlur={() => this.props.onSearchFocus(false)}
                   class={this.props.validationErrors && this.props.validationErrors.length ? 'evi-widget-input-error' : ''}/>
            {this.createAutocomplete()}
        </div>
    }
}
